<script>
import { mapGetters } from 'vuex';

import TicketCardV2 from '../../components/TicketCard.vue';
import TicketsManagerMixin from '~/features/tickets/mixins/TicketsManagerMixin';
import { showLoadMore, ticketsForDate } from '~/features/tickets/utils/common';

import TicketCard from '~/features/tickets/components/organisms/TicketCard';

export default defineNuxtComponent({
  components: { TicketCard, TicketCardV2 },
  mixins: [TicketsManagerMixin],
  props: {
    ticketsDates: {
      type: Set,
      required: true,
      default: () => [],
    },
    layoutType: {
      type: String,
      required: true,
      default: 'detailed',
    },
    loadingTickets: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('TicketsModuleStore', ['fetchedTickets']),
  },
  methods: {
    ticketsForDate,
    openTicketDetailsModal(ticket) {
      this.$router.push({ path: this.$route.path, query: { id: ticket.id } });
    },
    showLoadMore,
    loadMoreTickets() {
      this.$emit('loadMoreTickets');
    },
  },
});
</script>

<template>
  <div class="flex flex-col">
    <div class="mt-8">
      <perfect-scrollbar>
        <div
          v-for="(ticketDate, index) in ticketsDates"
          :key="index"
          :class="{ 'mt-10': index !== 0 }"
        >
          {{ ticketDate }}
          <div
            v-for="(ticket, ticketIndex) in ticketsForDate(
              fetchedTickets.data,
              ticketDate,
            )"
            :key="ticketIndex"
            class="mb-3"
            @click="openTicketDetailsModal(ticket)"
          >
            <TicketCardV2 :data="ticket" />
          </div>
        </div>

        <trailblazer-button
          v-if="showLoadMore(fetchedTickets)"
          variant="secondary"
          class="p-4"
          expand
          :disabled="loadingTickets"
          @click="loadMoreTickets"
        >
          {{
            loadingTickets
              ? __('Loading...')
              : __(
                sprintf(
                  'Load more (%d remaining)',
                  fetchedTickets.meta.total - fetchedTickets.data.length,
                ),
              )
          }}
        </trailblazer-button>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<style scoped>
.ps {
  max-height: calc(100vh - 315px);
}
</style>
